jQuery.easing.myEasing3 = function (x, t, b, c, d) {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}

import MicroModal from 'micromodal';

class App {
    constructor() {
        this.datas = [];
        this.slide_time = 3000;
        this.transition_time = 1000;
    }

    headerScroll() {
        let header = document.querySelector('.header');
        let t = function () {
            if ($(window).scrollTop() > 50) {
                $(header).addClass('on');
            } else {
                $(header).removeClass('on');
            }
        };
        window.addEventListener('scroll', () => {
            t();
        });
        window.addEventListener('load', () => {
            t();
        });
        window.addEventListener('resize', () => {
            t();
        });
    }

    gnav() {
        $('.burger-btn').on('click', function (e) {
            e.preventDefault();
            let $menu = $('.menu-trigger', this);
            $menu.toggleClass('active');
            if ($menu.hasClass('active')) {
                MicroModal.show('header-nav', {
                    disableScroll: true,
                    disableFocus: true,
                });
            } else {
                MicroModal.close('header-nav');
            }
            return false;
        });
    }

    scrollOberserve() {
        const boxes = document.querySelectorAll('.js-scroll');
        const options = {
            root: null,
            rootMargin: '0px 0px -20% 0px',
            threshold: 0,
        };
        const observer = new IntersectionObserver(intersect, options);
        boxes.forEach((box) => {
            observer.observe(box);
        });
        function intersect(entries) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    $(entry.target).addClass('on');
                }
            });
        }
    }

    smoothScroll() {
        $('.header-menu-sp a[href^="#"]').on('click', function () {
            MicroModal.close('float-nav');
        });
        $('a[href^="#"]').on('click', function (e) {
            e.preventDefault();
            let speed = 750;
            let href = $(this).attr('href');
            window.history.pushState({}, '', href);
            let target = $(href == '#' || href == '' ? 'html' : href);
            let position = target.offset().top;
            $('html, body').animate({ scrollTop: position }, speed, 'myEasing3');
        });
    }
    visualSlide() {
        $(function () {
            const $slider = $('.visual-image');
            let $slide = $slider.find('figure');
            if ($slide.length > 1) {
                $slider.slick({
                    arrows: false,
                    dots: false,
                    slidesToShow: 1,
                    autoplaySpeed: 3000,
                    speed: 1000,
                    infinite: true,
                    autoplay: true,
                    fade: true,
                    pauseOnFocus: false,
                    pauseOnHover: false,
                });
            } else {
                $slider.addClass('on');
            }
        });
    }

    casesSlide() {
        $(function () {
            const $slider = $('.slides');
            let $slide = $slider.find('.slide');
            if ($slide.length > 1) {
                const $thumbs = $('.slides-thumbs');
                $thumbs.append('<ul>');
                const $ul = $thumbs.find('ul');
                $slide.each(function () {
                    let $img = $(this).find('img').clone(false);
                    $ul.append($img);
                });

                $ul.find('img').each(function () {
                    $(this).wrap('<li><span></span></li>');
                });

                $slider.slick({
                    arrows: false,
                    dots: false,
                    slidesToShow: 1,
                    infinite: true,
                    asNavFor: $ul,
                    responsive: [{
                            breakpoint: 540,
                            settings: {
                                slidesToShow: 1,
                            },
                        },
                    ],
                });

                $ul.slick({
                    arrows: false,
                    dots: false,
                    slidesToShow: 5,
                    infinite: true,
                    asNavFor: '.slides',
                });

                $('.slick-slide', $ul).on('click', function() {
                    let index = $(this).attr('data-slick-index');
                    $slider.slick('slickGoTo', index);
                });
            } else {
                $slider.addClass('on');
            }
        });
    }

    init() {
        const _self = this;
        _self.headerScroll();
        _self.smoothScroll();
        _self.scrollOberserve();
        _self.visualSlide();
        _self.casesSlide();
        _self.gnav();
    }
}

let app = new App();

document.addEventListener('DOMContentLoaded', () => {
    app.init();
});
